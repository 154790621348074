import { addDaysToDate, addHoursToDate, addMinutesToDate, addMonthsToDate, addSecondsToDate, addYearsToDate } from './kyc-date-helpers';

const PresisionRegExp = /^(\d+)(s|m|h|d|M|y)$/;
const PrecisionUnitToDateFunctionsMap = {
  s: addSecondsToDate,
  m: addMinutesToDate,
  h: addHoursToDate,
  d: addDaysToDate,
  M: addMonthsToDate,
  y: addYearsToDate
};

export function addIntervalToDateByPrecision(date, precision) {
  const { unit, value } = computePrecisionDescriptor(precision);
  return PrecisionUnitToDateFunctionsMap[unit](date, value);
}

function computePrecisionDescriptor(precision) {
  if (!PresisionRegExp.test(precision)) {
    throw new Error('Invalid format of the precision string');
  }
  return { value: +RegExp.$1, unit: RegExp.$2 };
}
