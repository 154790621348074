<template>
  <kyc-chart
    :name="name"
    :title="title"
    :loading="loading"
    :options="options"
    :fullscreen="full"
    @click="navigateToPersonEventsPage"
    @toggle-view="$emit('toggle-view', id)"
  >
    <kyc-chart-paging slot="paging" :total="yAxisData.length" :page-size="pageSize" @change="handlePageChangeEvent" />
  </kyc-chart>
</template>

<script>
import { graphic } from 'echarts';
import { stringify } from 'qs';
import { Component, Vue } from 'vue-property-decorator';
import KycChart from './kyc-chart';
import KycChartPaging from './kyc-chart-paging';
import { axisPointerOpacity, gradientColors, textColor } from './kycConstants';
import { computePersonEventsFilterByKycFilter, PersonEventsFilterType } from './person-events-filters';

function getBarColor(type) {
  return new graphic.LinearGradient(1, 0, 0, 0, gradientColors[type]);
}

const MaxCamerasOnGraph = 6;

@Component({
  name: 'horizontal-barchart',
  props: ['data', 'name', 'title', 'id', 'full', 'loading'],
  components: { KycChart, KycChartPaging }
})
export default class extends Vue {
  pageSize = MaxCamerasOnGraph;
  indices = [0, 0];

  get filter() {
    return this.$store.state.kyc.filter.current;
  }

  get options() {
    const titleText = this.$tf(this.name);
    const barColor1 = getBarColor('unique');
    const barColor2 = getBarColor('all');

    return {
      title: {
        show: false,
        text: titleText,
        textStyle: {
          color: textColor
        }
      },
      tooltip: {
        show: this.full,
        axisPointer: {
          type: 'cross',
          lineStyle: {
            opacity: axisPointerOpacity
          },
          label: {
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            shadowBlur: 0,
            fontSize: 10,
            formatter: ({ value }) => (isNaN(value) ? value : Math.round(value))
          },
          padding: 10,
          textStyle: {
            color: '#fff'
          }
        },
        trigger: 'axis'
      },
      xAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: textColor
          }
        }
      },
      yAxis: {
        splitLine: {
          show: false
        },
        axisLine: {
          lineStyle: {
            color: textColor
          }
        },
        data: this.cameras
      },
      series: [
        {
          name: this.$tf('unique'),
          type: 'bar',
          itemStyle: {
            color: barColor1
          },
          label: {
            show: this.full,
            color: 'white',
            position: 'right'
          },
          barMaxWidth: '24px',
          data: this.uniqueVisitors
        },
        {
          name: this.$tf('total'),
          type: 'bar',
          itemStyle: {
            color: barColor2
          },
          label: {
            show: this.full,
            color: 'white',
            position: 'right'
          },
          barMaxWidth: '24px',
          data: this.totalVisitors
        }
      ],
      grid: {
        show: this.full,
        top: '48px',
        left: '32px',
        right: '32px',
        bottom: '8px',
        containLabel: true
      }
    };
  }

  get xAxisData() {
    return this.data.xAxisData || [];
  }

  get yAxisData() {
    return this.data.yAxisData || [];
  }

  get uniqueVisitors() {
    return getPageItems(this.xAxisData[0], this.indices);
  }

  get totalVisitors() {
    return getPageItems(this.xAxisData[1], this.indices);
  }

  get cameras() {
    return getPageItems(this.yAxisData, this.indices);
  }

  handlePageChangeEvent(indices) {
    this.indices = indices;
  }

  navigateToPersonEventsPage(dataIndex) {
    const cameraId = this.data.id[dataIndex];
    const filter = computePersonEventsFilterByKycFilter(PersonEventsFilterType.WITH_CUSTOM_CAMERA_ID, {
      filter: this.filter,
      cameraId
    });
    this.$router.push(`/person-events/filter/${stringify(filter)}`);
  }
}

function getPageItems(items, indices) {
  return (items && items.slice(...indices)) || [];
}
</script>
