import { toPng } from 'html-to-image/lib/index';

const GraphImageBackgroundColor = '#535559';
const ForbiddenElementsClassName = 'kyc-chart-actions';

export function createGraphImage(graphRootElement) {
  return toPng(graphRootElement, {
    backgroundColor: GraphImageBackgroundColor,
    filter: shouldElementBeRendered
  });
}

export function downloadGraphImageByObjectUrl(objectUrl, imageName) {
  const a = document.createElement('a');
  a.download = imageName;
  a.href = objectUrl;
  a.click();
}

function shouldElementBeRendered(node) {
  return isHTMLElement(node) ? !node.classList.contains(ForbiddenElementsClassName) : true;
}

function isHTMLElement(node) {
  return node.nodeType === Node.ELEMENT_NODE;
}
