<template>
  <kyc-chart-layout :enabled="enabled" v-loading="loading">
    <kyc-chart-graph :options="options" @click="dispatchClickEvent" slot="graph" ref="graph" />
    <kyc-chart-button-group :buttons="actions" slot="actions" />
    <kyc-chart-title :title="$tfo(title)" slot="title" />
    <slot slot="placeholder" name="placeholder" />
    <slot slot="subheader" name="subheader" />
    <slot slot="paging" name="paging" />
  </kyc-chart-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import KycChartButtonGroup from './kyc-chart-button-group';
import KycChartGraph from './kyc-chart-graph';
import { createGraphImage, downloadGraphImageByObjectUrl } from './kyc-chart-graph-helpers';
import KycChartLayout from './kyc-chart-layout';
import KycChartTitle from './kyc-chart-title';

@Component({
  name: 'KycChart',
  props: {
    name: { type: String, required: true },
    title: { type: String, required: true },
    enabled: { type: Boolean, default: true },
    loading: { type: Boolean, default: false },
    options: { type: Object, required: true },
    fullscreen: { type: Boolean, default: false }
  },
  components: {
    KycChartButtonGroup,
    KycChartGraph,
    KycChartLayout,
    KycChartTitle
  }
})
export default class extends Vue {
  get actions() {
    return [
      {
        classes: ['fa', 'fa-cloud-download'],
        handler: this.downloadGraphImage
      },
      {
        classes: ['fa', 'fa-' + (this.fullscreen ? 'compress' : 'arrows-alt')],
        handler: this.dispatchToggleViewEvent
      }
    ];
  }

  async downloadGraphImage() {
    let objectUrl;
    try {
      objectUrl = await createGraphImage(this.$el);
      const imageName = `${this.name}_${Date.now()}.png`;
      downloadGraphImageByObjectUrl(objectUrl, imageName);
    } catch (e) {
      this.notifyWhenThrown(e);
    } finally {
      URL.revokeObjectURL(objectUrl);
    }
  }

  dispatchToggleViewEvent() {
    this.$emit('toggle-view');
  }

  dispatchClickEvent(value) {
    this.$emit('click', value);
  }

  notifyWhenThrown(e) {
    this.$notify({
      duration: 0,
      message: this.$createElement('message-box', { props: { e } })
    });
  }
}
</script>
