<template>
  <h1 class="kyc-chart-title" v-text="title"></h1>
</template>

<script>
export default {
  name: 'KycChartTitle',
  props: { title: { type: String, required: true } }
};
</script>

<style>
.kyc-chart-title {
  margin: 0;
  font-size: 1.25rem;
}
</style>
