<template>
  <div class="kyc-chart-statistics">
    <kyc-chart-statistics-item v-for="(item, index) of statistics" v-bind="item" :key="index" />
  </div>
</template>

<script>
import KycChartStatisticsItem from './kyc-chart-statistics-item';

export default {
  name: 'KycChartStatistics',
  props: { statistics: { type: Array, required: true } },
  components: { KycChartStatisticsItem }
};
</script>

<style lang="stylus">
.kyc-chart-statistics {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
