<template>
  <p class="kyc-chart-precision">{{ $tf('precision') }}: {{ $tt(...precisionLocaleStrings) }}</p>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'KycChartPrecision',
  props: {
    precision: { type: String, required: true }
  }
})
export default class extends Vue {
  get state() {
    return this.$store.state.kyc;
  }

  get precisionLocaleStrings() {
    return this.state.DateIntervalToI18n[this.precision];
  }
}
</script>

<style>
.kyc-chart-precision {
  margin: 0;
}
</style>
