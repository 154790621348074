<template>
  <p class="kyc-chart-statistics-summary" v-text="summary"></p>
</template>

<script>
export default {
  name: 'KycChartStatisticsSummary',
  props: { summary: { type: Number, required: true } }
};
</script>

<style>
.kyc-chart-statistics-summary {
  margin: 0;
  color: white;
  font-size: 2rem;
}
</style>
