<template>
  <div class="kyc-chart-button-group">
    <kyc-chart-button
      v-for="(button, index) of buttons"
      class="kyc-chart-button-group__item"
      :disabled="button.disabled"
      :classes="button.classes"
      @click="button.handler"
      :key="index"
    />
  </div>
</template>

<script>
import KycChartButton from './kyc-chart-button';

export default {
  name: 'KycChartButtonGroup',
  props: {
    buttons: { type: Array, required: true }
  },
  components: { KycChartButton }
};
</script>

<style lang="stylus">
.kyc-chart-button-group {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &__item + &__item {
    margin-left: 0.625rem;
  }
}
</style>
