<template>
  <div class="kyc-chart-statistics-item">
    <strong class="kyc-chart-statistics-item__percentage">
      {{ percentage | floatToPercent }}
    </strong>
    <em class="kyc-chart-statistics-item__description" v-text="description"></em>
  </div>
</template>

<script>
export default {
  name: 'KycChartStatisticsItem',
  props: {
    percentage: { type: Number, required: true },
    description: { type: String, required: true }
  }
};
</script>

<style lang="stylus">
.kyc-chart-statistics-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;

  &__percentage {
    color: white;
    font-size: 2rem;
    font-weight: normal;
  }

  &__description {
    font-size: 0.625rem;
    margin-top: -0.5rem;
    font-style: normal;
  }
}
</style>
