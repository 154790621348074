<template>
  <i :class="classList" @click="dispatchClickEvent" class="kyc-chart-button"></i>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'KycChartButton',
  props: {
    classes: { type: Array, required: true },
    disabled: { type: Boolean, default: false }
  }
})
export default class extends Vue {
  get classList() {
    return this.disabled ? ['disabled', ...this.classes] : this.classes;
  }

  dispatchClickEvent() {
    !this.disabled && this.$emit('click');
  }
}
</script>

<style lang="stylus">
.kyc-chart-button {
  cursor: pointer;

  &::before {
    width: 1.125rem;
    display: inline-block;
    text-align: center;
  }

  &.disabled {
    opacity: 0.5;
  }

  &:hover:not(.disabled) {
    color: white;
  }
}
</style>
