const HoursInOneDay = 24;
const MonthsInOneYear = 12;
const MinutesInOneHour = 60;
const SecondsInOneMinute = 60;
const MillisecondsInOneSecond = 1e3;
const getDaysInKnuckleMonth = () => 31;
const getDaysInNonKnuckleMonth = () => 30;

const MonthToDaysMap = [
  getDaysInKnuckleMonth,
  (year) => 28 + isLeapYear(year),
  getDaysInKnuckleMonth,
  getDaysInNonKnuckleMonth,
  getDaysInKnuckleMonth,
  getDaysInNonKnuckleMonth,
  getDaysInKnuckleMonth,
  getDaysInKnuckleMonth,
  getDaysInNonKnuckleMonth,
  getDaysInKnuckleMonth,
  getDaysInNonKnuckleMonth,
  getDaysInKnuckleMonth
];

export function addYearsToDate(date, years) {
  return addMonthsToDate(date, years * MonthsInOneYear);
}

export function addMonthsToDate(date, months) {
  const monthGt = date.getMonth();
  const monthLt = monthGt + months;

  for (let month = monthGt; month < monthLt; month++) {
    const computeDaysInMonth = MonthToDaysMap[month % MonthsInOneYear];
    addDaysToDate(date, computeDaysInMonth(date.getFullYear()));
  }

  return date;
}

export function addDaysToDate(date, days) {
  return addHoursToDate(date, days * HoursInOneDay);
}

export function addHoursToDate(date, hours) {
  return addMinutesToDate(date, hours * MinutesInOneHour);
}

export function addMinutesToDate(date, minutes) {
  return addSecondsToDate(date, minutes * SecondsInOneMinute);
}

export function addSecondsToDate(date, seconds) {
  return addMillisecondsToDate(date, seconds * MillisecondsInOneSecond);
}

export function addMillisecondsToDate(date, milliseconds) {
  return date.setTime(+date + milliseconds), date;
}

function isLeapYear(year) {
  return (!(year % 4) && !!(year % 100)) || !(year % 400);
}
