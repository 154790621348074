const gradientColors = {
  all: [
    { offset: 1, color: '#EDE388' },
    { offset: 0, color: '#EDE388' }
  ],
  unique: [
    { offset: 1, color: '#8CC3A8' },
    { offset: 0, color: '#8CC3A8' }
  ],
  default: [
    { offset: 1, color: '#555555' },
    { offset: 0, color: '#bbbbbb' }
  ]
};

const textColor = 'rgba(255, 255, 255, 0.5)';

const axisPointerOpacity = 0.4;

const Colors = ['blue', 'red', 'blue'];

const StatisticsDescriptions = {
  gender: ['male', 'female'],
  visits: ['new,,3', 'returning']
};

const PrecisionFormatters = {
  '1m': 'yyyy-MM-dd HH:mm',
  '1h': 'yyyy-MM-dd HH:mm',
  '1d': 'yyyy-MM-dd',
  '1w': 'yyyy-MM-dd',
  '1M': 'yyyy-MM',
  '1y': 'yyyy-MM'
};

const SeriesNameToValueToken = {
  male: 'men',
  female: 'women',
  age: 'age_pf',
  visitors: 'visitors_pf',
  visits: 'visits_pf',
  new: 'new_visits_pf',
  returning: 'returning_visits_pf'
};

export { gradientColors, textColor, axisPointerOpacity, Colors, SeriesNameToValueToken, PrecisionFormatters, StatisticsDescriptions };
