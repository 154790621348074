<template>
  <div class="kyc-chart-layout">
    <div class="kyc-chart-layout__wrapper">
      <div class="kyc-chart-header">
        <div class="kyc-chart-header__title">
          <slot name="title" />
        </div>
        <div v-if="enabled" class="kyc-chart-actions">
          <div v-if="this.$slots.paging" class="kyc-chart-actions__paging">
            <slot name="paging" />
          </div>
          <slot name="actions" />
        </div>
      </div>
      <slot v-if="enabled" name="subheader" />
    </div>
    <slot v-if="enabled" name="graph" />
    <div v-else class="kyc-chart-layout__placeholder">
      <slot name="placeholder" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'KycChartLayout',
  props: {
    enabled: { type: Boolean, default: true }
  }
};
</script>

<style lang="stylus">
.kyc-chart {
  &-layout {
    width: 100%;
    height: 100%;
    min-width: 15rem;
    overflow: hidden;
    position: relative;
    border-radius: 1rem;
    background: rgba(255, 255, 255, 0.2);

    &__wrapper {
      top: 0;
      left: 0;
      right: 0;
      z-index: 1;
      padding: 0.75rem;
      position: absolute;
    }

    &__placeholder {
      top: 50%;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  &-header, &-actions {
    display: flex;
    align-items: center;
  }

  &-header {
    &__title {
      flex: 1;
      margin-right: 0.625em;
    }
  }

  &-actions {
    &__paging {
      margin-right: 0.625em;
    }
  }
}
</style>
