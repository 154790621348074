<template>
  <kyc-chart
    :name="name"
    :title="title"
    :enabled="enabled"
    :loading="loading"
    :options="options"
    :fullscreen="full"
    @click="navigateToPersonEventsPage"
    @toggle-view="$emit('toggle-view', id)"
  >
    <kyc-chart-precision v-if="full" :precision="precision" slot="subheader" />
    <kyc-chart-statistics v-else-if="statistics" :statistics="statistics" slot="subheader" />
    <kyc-chart-statistics-summary v-else-if="isSummaryExists" :summary="summary" slot="subheader" />
    <el-button v-text="$tf('enable')" slot="placeholder" @click="$emit('enable', id)" />
  </kyc-chart>
</template>

<script>
import * as filters from '@/apps/common/filters';
import echarts from 'echarts';
import { stringify } from 'qs';
import KycChart from './kyc-chart';
import KycChartPrecision from './kyc-chart-precision';
import KycChartStatistics from './kyc-chart-statistics';
import KycChartStatisticsSummary from './kyc-chart-statistics-summary';
import { axisPointerOpacity, PrecisionFormatters, SeriesNameToValueToken, StatisticsDescriptions, textColor } from './kycConstants';
import { computePersonEventsFilterByKycFilter, PersonEventsFilterType } from './person-events-filters';

const colors = ['blue', 'red', 'blue'];

export default {
  components: {
    KycChart,
    KycChartPrecision,
    KycChartStatistics,
    KycChartStatisticsSummary
  },
  name: 'area-chart',
  props: ['data', 'name', 'title', 'id', 'full', 'enabled', 'loading', 'opacity', 'convertToPercent'],
  computed: {
    statistics() {
      return (
        this.data.overall &&
        this.data.overall.map((percentage, index) => ({
          percentage,
          description: this.descriptions[index]
        }))
      );
    },
    summary() {
      return this.data.total || this.data.average;
    },
    isSummaryExists() {
      return Number.isFinite(this.summary);
    },
    colors() {
      const items = {
        visitors: [['#eee580', '#8ac3a8']],
        gender: [['#4a90e2', '#95bbe8'], '#f23cac'],
        visits: ['#dade88', '#f9636d'],
        age: [['#d9906b', '#8c6ce6']]
      };
      return items[this.name] || colors;
    },
    descriptions() {
      return (StatisticsDescriptions[this.name] || []).map(this.$tf);
    },
    precision() {
      return this.$store.state.kyc.filter.current.precision;
    },
    filter() {
      return this.$store.state.kyc.filter.current;
    },
    options() {
      return {
        title: {
          show: false
        },
        tooltip: {
          show: this.full,
          formatter: this.formatTooltip,
          axisPointer: {
            type: 'cross',
            lineStyle: {
              opacity: axisPointerOpacity
            },
            label: {
              backgroundColor: 'rgba(0, 0, 0, 0.3)',
              shadowBlur: 0,
              fontSize: 10,
              formatter: this.formatAxisLabel
            },
            padding: 10,
            textStyle: {
              color: '#fff'
            }
          },
          trigger: 'axis'
        },
        xAxis: {
          data: this.data.xAxisData,
          type: 'time',
          axisLine: {
            lineStyle: {
              color: textColor
            }
          },
          splitLine: {
            show: this.full
          },
          axisLabel: {
            show: true,
            interval: 0,
            overflow: false,
            color: 'rgba(255, 255, 255, 0.4)',
            fontSize: 10,
            formatter: (v) => this.formatDate(v, { splitTimeLine: true })
          }
        },
        yAxis: {
          show: this.full,
          padding: 0,
          type: 'value',
          axisLine: {
            lineStyle: {
              color: textColor
            }
          },
          splitLine: {
            show: this.full
          },
          axisLabel: {
            show: true,
            interval: 0,
            overflow: false,
            color: 'rgba(255, 255, 255, 0.4)',
            fontSize: 10,
            formatter: this.formatValue
          },
          max: this.getYMaxValue()
        },
        grid: {
          show: false,
          top: '80px',
          left: this.full ? '32px' : 0,
          right: this.full ? '32px' : 0,
          bottom: '32px',
          containLabel: this.full
        },
        series:
          this.data.yAxisData &&
          this.data.yAxisData.map((data, i) => ({
            data: this.getYAxisData(data),
            name: this.data.yAxisNames && this.data.yAxisNames[i],
            type: 'line',
            showSymbol: false,
            showAllSymbol: false,
            color: this.colors[i],
            areaStyle: {
              color: this.getAreaColor(this.colors[i]),
              opacity: this.opacity || 1
            },
            lineStyle: {
              opacity: 0,
              color: this.colors[i]
            }
          }))
      };
    }
  },
  created() {
    this.formatDate = this.formatDate.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.formatAxisLabel = this.formatAxisLabel.bind(this);
    this.formatTooltip = this.formatTooltip.bind(this);
  },
  methods: {
    formatAxisLabel(v) {
      let r = v.value;

      if (v.axisDimension === 'x') {
        r = this.formatDate(v.value);
      } else if (v.axisDimension === 'y') {
        r = this.formatValue(v.value);
      }

      return r;
    },
    formatTooltip(v) {
      let r = '';
      const { counterData } = this.data;
      v.forEach((v, k) => {
        try {
          if (k === 0) r += v.axisValueLabel;
          const counterNumber = counterData && counterData[k][v.dataIndex];
          const counterLabel = counterData ? ` (${counterNumber} ${this.$tt('persons_pf', counterNumber)})` : '';
          r += `<br/><span style="color:${v.color};">&#x25cf;</span> <span>${this.formatValue(v.value[1], v.seriesName)}${counterLabel}</span>`;
        } catch (e) {
          r += `<br/> [value-error] ${k}`;
        }
      });
      return r;
    },
    formatDate(v, { splitTimeLine } = {}) {
      const date = new Date(v),
        { precision } = this.filter;

      let r = filters.formatDateTime(date, PrecisionFormatters[precision]);
      return splitTimeLine ? r.split(' ').join('\n') : r;
    },
    formatValue(v, seriesName) {
      let formattedValue = this.convertToPercent ? `${Math.round(v)}%` : Math.round(v),
        valueToken = SeriesNameToValueToken[seriesName] || '',
        isPluralValueToken = !!valueToken.match(/^.*_pf$/),
        translatedToken = isPluralValueToken ? this.$tt(valueToken, v) : this.$tf(valueToken),
        r = null;

      r = valueToken ? `${formattedValue} ${translatedToken}` : formattedValue;

      return r;
    },
    convertValue(v) {
      return this.convertToPercent ? v : v;
    },
    getYMaxValue() {
      return this.convertToPercent ? 100 : null;
    },
    getYAxisData(data) {
      return data.map((v, i) => [this.data.xAxisData[i], this.convertValue(v)]);
    },
    getAreaColor(v) {
      let color = Array.isArray(v)
        ? new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            {
              offset: 0,
              color: v[0]
            },
            {
              offset: 1,
              color: v[1]
            }
          ])
        : v;
      return color;
    },
    navigateToPersonEventsPage(dataIndex) {
      const created_date_gte = this.data.xAxisData[dataIndex];
      const filter = computePersonEventsFilterByKycFilter(PersonEventsFilterType.WITH_CUSTOM_CREATED_DATES, {
        filter: this.filter,
        created_date_gte
      });
      this.$router.push(`/person-events/filter/${stringify(filter)}`);
    }
  }
};
</script>
