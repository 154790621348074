<template>
  <div class="kyc-chart-graph"></div>
</template>

<script>
import echarts from 'echarts';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  name: 'KycChartGraph',
  props: {
    options: { type: Object, required: true }
  }
})
export default class extends Vue {
  graph = null;

  mounted() {
    this.graph = echarts.init(this.$el);
    this.graph.on('click', this.dispatchClickEvent);
    window.addEventListener('resize', this.resize);
    this.setGraphOptions();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.resize);
    this.graph.dispose();
    this.graph = null;
  }

  @Watch('options', { deep: true })
  handleOptionsChange() {
    this.setGraphOptions();
  }

  setGraphOptions() {
    this.graph.setOption(this.options);
    this.resize();
  }

  resize() {
    this.graph.resize();
  }

  dispatchClickEvent({ dataIndex }) {
    this.$emit('click', dataIndex);
  }
}
</script>

<style>
.kyc-chart-graph {
  width: 100%;
  height: 100%;
}
</style>
