<template>
  <div class="kyc-chart-paging">
    <p class="kyc-chart-paging__counter">{{ page }} / {{ pages }}</p>
    <kyc-chart-button-group :buttons="buttons" />
  </div>
</template>

<script>
import { Component, Vue, Watch } from 'vue-property-decorator';
import KycChartButtonGroup from './kyc-chart-button-group';

const StartPageIndex = 1;
const MinPagesAmount = 1;
const PrevPageButtonClasses = ['fa', 'fa-chevron-left'];
const NextPageButtonClasses = ['fa', 'fa-chevron-right'];

@Component({
  name: 'KycChartPaging',
  props: {
    total: { type: Number, required: true },
    pageSize: { type: Number, required: true }
  },
  components: { KycChartButtonGroup }
})
export default class extends Vue {
  page = StartPageIndex;

  get buttons() {
    return [
      {
        handler: this.gotoPrevPage,
        classes: PrevPageButtonClasses,
        disabled: this.isPrevPageButtonDisabled
      },
      {
        handler: this.gotoNextPage,
        classes: NextPageButtonClasses,
        disabled: this.isNextPageButtonDisabled
      }
    ];
  }

  get isPrevPageButtonDisabled() {
    return this.page === StartPageIndex;
  }

  get isNextPageButtonDisabled() {
    return this.page === this.pages;
  }

  get pages() {
    return Math.ceil(this.total / this.pageSize) || MinPagesAmount;
  }

  @Watch('pages')
  handlePagesAmountChange() {
    this.page = StartPageIndex;
  }

  @Watch('page', { immediate: true })
  handlePageChange() {
    const begin = (this.page - 1) * this.pageSize;
    this.$emit('change', [begin, begin + this.pageSize]);
  }

  gotoPrevPage() {
    this.page--;
  }

  gotoNextPage() {
    this.page++;
  }
}
</script>

<style lang="stylus">
.kyc-chart-paging {
  display: flex;
  align-items: center;

  &__counter {
    margin: 0;
    font-size: 0.75em;
    margin-right: 0.5em;
    white-space: nowrap;
  }
}
</style>
