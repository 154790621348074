import { addIntervalToDateByPrecision } from './kyc-precision-helpers';

export const PersonEventsFilterType = {
  WITH_CUSTOM_CREATED_DATES: 'WITH_CUSTOM_CREATED_DATES',
  WITH_CUSTOM_CAMERA_ID: 'WITH_CUSTOM_CAMERA_ID'
};

export function computePersonEventsFilterByKycFilter(filterType, payload) {
  switch (filterType) {
    case PersonEventsFilterType.WITH_CUSTOM_CREATED_DATES:
      return computeFilterWithCreatedDates(payload);
    case PersonEventsFilterType.WITH_CUSTOM_CAMERA_ID:
      return computeFilterWithCustomCamera(payload);
    default:
      throw new Error(`The '${filterType}' filter type isn't supported`);
  }
}

function computeFilterWithCreatedDates({ filter, created_date_gte }) {
  const filterAddon = computeCreatedDatesFilterAddon(created_date_gte, filter.precision);
  return computeFilter(filter, filterAddon);
}

function computeFilterWithCustomCamera({ filter, cameraId }) {
  return computeFilter(filter, { cameras: [cameraId] });
}

function computeFilter(filter, filterAddon) {
  return Object.assign(computeBaseFilter(filter), filterAddon);
}

function computeBaseFilter(filter) {
  return {
    cameras: filter.cameras,
    age_gte: filter.age_gte,
    age_lte: filter.age_lte,
    matched_lists: filter.watch_lists,
    gender: [filter.gender],
    created_date_gte: filter.created_date_gte,
    created_date_lte: filter.created_date_lte
  };
}

function computeCreatedDatesFilterAddon(created_date_gte, precision) {
  const date = new Date(created_date_gte);
  created_date_gte = date.toISOString();
  addIntervalToDateByPrecision(date, precision);
  const created_date_lte = date.toISOString();
  return { created_date_gte, created_date_lte };
}
